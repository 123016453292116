import { Button } from '@shared/design-system/atoms/button';
import Image from 'next/image';
import Link from 'next/link';

import CheckSvg from '~/assets/icons/check.svg';
import WomanDrinkingProductImage from '~/assets/images/upsells/book/woman-drinking-product.png';
import { Content } from '~/components/content';
import { useAppLead } from '~/hooks/useAppLead';
import { AppRoute } from '~/types';

import ProductCard from './product-card';


const listItems = [
  { id: 1, text: '4 Transformational Learning Modules – A self-paced journey into holistic hormone healing' },
  { id: 2, text: 'Downloadable Guides & Worksheets – Thoughtfully designed tools to help you integrate each lesson' },
  { id: 3, text: 'Personalized Cortisol Reset Plan – A custom framework tailored to your unique needs' },
  { id: 4, text: 'Lifetime Access – Move through the course at your own rhythm, revisiting as needed' },
];

const Purchase = () => {
  const { orderNumber } = useAppLead();
  const nextRoute = `${AppRoute.ORDER}${orderNumber ? `?orderNumber=${orderNumber}` : ''}`;

  return (
    <Content>
      <div className='flex flex-col items-center md:px-4 py-8'>
        <div className='w-full md:max-w-[62.5rem]'>
          <div className='flex md:flex-row-reverse flex-col md:gap-10'>
            <div className='hidden md:block flex-1'>
              <Image src={WomanDrinkingProductImage} width={464} height={737} alt='Purchase' />
            </div>
            <div className='flex flex-col flex-1 gap-4'>
              <div className='flex flex-col items-center gap-4 md:px-4 md:pb-6'>
                <div className='flex justify-center'>
                  <p className='bg-green-600 px-2 py-0.5 rounded-full font-bold text-white text-xs'>
                    VALID ON THIS PAGE ONLY!
                  </p>
                </div>
                <h2 className='font-tertiary font-bold text-5xl text-center leading-none'>
                  Unlock your body’s natural healing pot﻿ential
                </h2>
                <p className='font-tertiary text-2xl text-center leading-none'>
                  Get Instant Access to All Modules & Resource﻿s
                </p>
                <div className='flex flex-col gap-2 md:px-8'>
                  {listItems.map((item) => (
                    <div key={item.id} className='flex items-start gap-2'>
                      <div className='shrink-0'>
                        <CheckSvg />
                      </div>
                      <p className='text-xs'>{item.text}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className='flex flex-col md:self-center gap-4 w-full md:max-w-[21.875rem]'>
                <ProductCard nextRoute={nextRoute} />
                <Link href={nextRoute}>
                  <Button
                    variant='link'
                    size='md'
                    className='border border-black/5 text-orange-600 no-underline w-full'
                  >
                    I don’t want this offer
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default Purchase;
