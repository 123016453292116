import { SavePaymentMethodRequest } from '@menesko/models-harmonia';
import { useMutation, UseMutationOptions } from 'react-query';

import { APIClient, FunctionName, getAPIUrl } from '~/firebase-init';

export const usePaymentMethodMutation = (
  options?: Omit<UseMutationOptions<{ success: true }, unknown, SavePaymentMethodRequest, unknown>, 'mutationKey'>,
) => {
  return useMutation<{ success: true }, unknown, SavePaymentMethodRequest>(FunctionName.PAYMENT_METHODS, {
    mutationFn: (data) =>
      APIClient.post<SavePaymentMethodRequest, { success: true }>(getAPIUrl(FunctionName.PAYMENT_METHODS), data),
    ...options,
  });
};
