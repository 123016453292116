import Header from '~/layouts/_shared/header';

import Complement from './complement';
import Hero from './hero';
import Masterclass from './masterclass';
import Purchase from './purchase';
import Stepper from './stepper';
import Unlock from './unlock';

export const UpsellBook = () => {
  return (
    <div className='[--max-content:1200px]'>
      <Header bottomBorder={true} />
      <Stepper />
      <Hero />
      <Masterclass />
      <Unlock />
      <Complement />
      <Purchase />
    </div>
  );
};
