import Image from 'next/image';

import HeroTabletImage from '~/assets/images/upsells/book/hero-tablet.png';
import { Content } from '~/components/content';

const Hero = () => {
  return (
    <Content viewClassName='md:col-[edge-left_/_edge-right]'>
      <div className='flex md:flex-row flex-col md:items-center gap-6 md:gap-0'>
        <div className='flex flex-col flex-1 md:items-end'>
          <div className='md:max-w-[39.9375rem]'>
            <div className='flex flex-col items-center gap-6 md:gap-8 md:px-4 md:py-12 pt-4'>
              <div className='relative p-4 border border-orange-200 rounded-2xl w-full md:max-w-[34.0625rem]'>
                <div className='-top-2.5 left-1/2 absolute bg-white px-2.5 md:px-4 -translate-x-1/2'>
                  <div className='bg-orange-600 px-2 py-0.5 rounded-full'>
                    <p className='md:font-normal font-bold text-white text-xs text-base leading-[130%] tracking-[-0.12px]'>
                      SPECIAL OFFER!
                    </p>
                  </div>
                </div>
                <h1 className='pt-4 pb-6 font-tertiary font-bold text-6xl text-center leading-none'>
                  The Cortisol Reset Blueprint
                </h1>
                <div className='-bottom-4 left-1/2 absolute bg-white px-4 md:px-10 -translate-x-1/2'>
                  <p className='font-tertiary text-black text-base uppercase leading-[220%] tracking-[8.16px]'>
                    Masterclass
                  </p>
                </div>
              </div>
              <p className='md:max-w-[29.625rem] font-tertiary font-bold text-xl md:text-3xl text-center leading-none tracking-[-0.2px] md:tracking-[-0.64px]'>
                Do you feel like you heard a thing or two about hormones, but would love to learn more?
              </p>
            </div>
          </div>
        </div>

        <div className='flex-1 -mx-4 md:mx-0'>
          <Image src={HeroTabletImage} width={940} height={643} alt='Hero cover' className='w-full' />
        </div>
      </div>
    </Content>
  );
};

export default Hero;
