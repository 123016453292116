import Image from 'next/image';

import CheckCircleOrangeSvg from '~/assets/icons/check-circle-orange.svg';
import ProductAndTabletImage from '~/assets/images/upsells/book/product-and-tablet.png';
import { Content } from '~/components/content';

const Complement = () => {
  return (
    <Content gridClassName='bg-orange-50'>
      <div className='flex flex-col items-center gap-4 py-8 md:pt-20 md:pb-14'>
        <p className='md:max-w-[39rem] font-tertiary text-2xl text-center leading-none'>
          Complement your Harmonia cocktail. This masterclass is your next step—a way to bring those principles into
          your lifestyle for deep, lasting transformation.
        </p>
        <Image src={ProductAndTabletImage} width={706} height={419} alt='Cortisol cocktail complement' />
        <h2 className='md:max-w-[37rem] font-tertiary font-bold text-3xl md:text-5xl text-center leading-none tracking-[-0.96px]'>
          {"Why You'll Cortisol Reset Blueprint Masterclass"}
        </h2>
        <div className='gap-6 md:gap-10 grid grid-cols-1 md:grid-cols-3 pt-6 md:max-w-[48.75rem]'>
          <div className='flex md:flex-col items-start md:items-center gap-3'>
            <div className='shrink-0'>
              <CheckCircleOrangeSvg />
            </div>
            <p className='font-medium text-base md:text-center'>
              Rooted in the Harmonia Philosophy - A blend of science, intuition, and natural rhythms
            </p>
          </div>
          <div className='flex md:flex-col items-start md:items-center gap-3'>
            <div className='shrink-0'>
              <CheckCircleOrangeSvg />
            </div>
            <p className='font-medium text-base md:text-center'>
              Sustainable & Nourishing - No extreme measures, just simple, effective shifts
            </p>
          </div>
          <div className='flex md:flex-col items-start md:items-center gap-3'>
            <div className='shrink-0'>
              <CheckCircleOrangeSvg />
            </div>
            <p className='font-medium text-base md:text-center'>
              Designed for Real Life - A framework that adapts to your unique body and lifestyle
            </p>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default Complement;
