import { Content } from '~/components/content';

function Stepper() {
  return (
    <Content>
      <div className='grid grid-cols-3 pt-4 md:pt-6 pb-6 md:pb-8'>
        <div className='relative'>
          <div className='z-10 relative flex flex-col items-center gap-2'>
            <div className='bg-orange-600 rounded-full w-3.5 h-3.5' />
            <p className='md:font-semibold text-xs md:text-sm text-center'>Order Submitted</p>
          </div>
        </div>
        <div className='relative'>
          <div className='top-[0.3125rem] right-[calc(50%-0rem)] left-[calc(-50%-0rem)] z-0 absolute bg-orange-600 h-1' />
          <div className='z-10 relative flex flex-col items-center gap-2'>
            <div className='bg-orange-600 rounded-full w-3.5 h-3.5' />
            <p className='md:font-semibold text-xs md:text-sm text-center'>Special Offer</p>
          </div>
        </div>
        <div className='relative'>
          <div className='top-[0.3125rem] right-[calc(50%-0rem)] left-[calc(-50%-0rem)] z-0 absolute bg-orange-200 h-1' />
          <div className='z-10 relative flex flex-col items-center gap-2'>
            <div className='bg-orange-200 rounded-full w-3.5 h-3.5' />
            <p className='md:font-semibold text-xs md:text-sm text-center'>Order Receipt</p>
          </div>
        </div>
      </div>
    </Content>
  );
}

export default Stepper;
