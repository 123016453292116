import { CreateUpsellRequest, CreateUpsellResponse } from '@menesko/models-harmonia';
import { useMutation, UseMutationOptions } from 'react-query';

import { APIClient, FunctionName, getAPIUrl } from '~/firebase-init';

export const useUpsellMutation = (
  options?: Omit<
    UseMutationOptions<CreateUpsellResponse, { code: 'order_not_found' }, CreateUpsellRequest, unknown>,
    'mutationKey'
  >,
) => {
  return useMutation<CreateUpsellResponse, { code: 'order_not_found' }, CreateUpsellRequest>(
    FunctionName.PAYMENT_UPSELL,
    {
      mutationFn: (data) =>
        APIClient.post<CreateUpsellRequest, CreateUpsellResponse>(getAPIUrl(FunctionName.PAYMENT_UPSELL), data),
      ...options,
    },
  );
};
