import Image, { StaticImageData } from 'next/image';
import React from 'react';

import CalendarSvg from '~/assets/icons/calendar.svg';
import CheckSvg from '~/assets/icons/check.svg';

type Props = {
  week: number;
  title: string;
  items: string[];
  image: StaticImageData;
};

const WeekCard = ({ week, title, items, image }: Props) => {
  return (
    <div className='bg-orange-50 p-6 md:p-8 md:pb-0 rounded-2xl'>
      <div className='flex md:flex-row flex-col gap-8'>
        <div className='hidden md:block flex-1 self-end max-w-[12.5625rem]'>
          <Image src={image} width={201} height={286} alt={title} />
        </div>

        <div className='flex flex-col flex-1 gap-4 md:pb-8'>
          <div className='flex justify-between gap-4 border-b border-black/5 md:border-none'>
            <div className='flex flex-col justify-end gap-2 pb-4 md:pb-0'>
              <div className='flex items-center gap-2'>
                <div className='shrink-0'>
                  <CalendarSvg />
                </div>
                <p className='font-semibold text-sm leading-[140%] tracking-[-0.14px]'>Week {week}</p>
              </div>
              <p className='font-tertiary font-bold text-xl leading-none tracking-[-0.2px]'>{title}</p>
            </div>
            <Image src={image} width={80} height={114} alt={title} className='md:hidden' />
          </div>

          <ul className='flex flex-col gap-1 list-none'>
            {items.map((item) => (
              <li key={item} className='flex items-center gap-2'>
                <div className='shrink-0'>
                  <CheckSvg />
                </div>
                <p className='text-sm leading-[130%] tracking-[-0.12px]'>{item}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WeekCard;
