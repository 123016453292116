import BatterySvg from '~/assets/icons/battery.svg';
import LashSvg from '~/assets/icons/lash.svg';
import ScaleSvg from '~/assets/icons/scale.svg';
import ShieldSvg from '~/assets/icons/shield.svg';
import { Content } from '~/components/content';

const Masterclass = () => {
  return (
    <Content>
      <div className='bg-orange-50 -mx-4 md:mx-0 p-6 md:px-12 md:pt-8 md:pb-14 md:rounded-3xl'>
        <div className='flex md:flex-row flex-col gap-8'>
          <div className='flex-1 md:max-w-[17.375rem]'>
            <p className='font-tertiary text-2xl md:text-left text-center leading-none'>
              This masterclass is for women who want to:
            </p>
          </div>
          <div className='flex-1 gap-4 md:gap-10 grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
            <div className='flex flex-col items-center md:items-start gap-2'>
              <BatterySvg />
              <p className='text-sm md:text-left text-center leading-[140%] tracking-[-0.14px]'>
                Move from surviving to thriving by restoring their natural energy
              </p>
            </div>
            <div className='flex flex-col items-center md:items-start gap-2'>
              <ShieldSvg />
              <p className='text-sm md:text-left text-center leading-[140%] tracking-[-0.14px]'>
                Break free from cortisol crashes, mood swings, and hormone imbalances
              </p>
            </div>
            <div className='flex flex-col items-center md:items-start gap-2'>
              <LashSvg />
              <p className='text-sm md:text-left text-center leading-[140%] tracking-[-0.14px]'>
                Align their daily habits with their body’s natural rhythm for lasting well-being
              </p>
            </div>
            <div className='flex flex-col items-center md:items-start gap-2'>
              <ScaleSvg />
              <p className='text-sm md:text-left text-center leading-[140%] tracking-[-0.14px]'>
                Go beyond basic wellness routines and experience true hormonal harmony
              </p>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default Masterclass;
