import { CreateUpsellNextStepsResponse } from '@menesko/models-harmonia';
import { Dialog, DialogClose, DialogContent } from '@shared/design-system/atoms/dialog';
import { StripeCreatePaymentMethod, StripeWrapper } from '@shared/payments';
import { useStripe } from '@stripe/react-stripe-js';
import { useCallback, useEffect, useState } from 'react';

import { config } from '~/config';
import { usePaymentMethodMutation } from '~/hooks/use-payment-method-update';
import { useAppLead } from '~/hooks/useAppLead';

export const NextStepsHandler = ({
  nextSteps,
  onClose,
  onSuccess,
  onRetry,
}: {
  nextSteps: CreateUpsellNextStepsResponse;
  onClose: () => void;
  onSuccess: () => void;
  onRetry: () => void;
}) => {
  const { id: leadId } = useAppLead();
  const { mutate: paymentMethodMutation } = usePaymentMethodMutation({
    onSuccess: () => {
      onRetry();
    },
  });

  const handlePaymentUpdate = ({ paymentMethodId }: { paymentMethodId: string }) => {
    paymentMethodMutation({ leadId, paymentMethodId });
  };

  return (
    <StripeWrapper apiKey={config.STRIPE.API_KEY}>
      {nextSteps.code === 'use_stripe_sdk' && nextSteps.client_secret ? (
        <StripeNextActionHandler clientSecret={nextSteps.client_secret} onSuccess={onSuccess} />
      ) : (
        <PaymentMethodUpdateModal onSuccess={handlePaymentUpdate} onClose={onClose} />
      )}
    </StripeWrapper>
  );
};

const StripeNextActionHandler = ({ clientSecret, onSuccess }: { clientSecret: string; onSuccess: () => void }) => {
  const stripe = useStripe();
  const [error, setError] = useState('');

  const handleLoad = useCallback(async () => {
    if (stripe) {
      const res = await stripe?.handleNextAction({ clientSecret });

      if (res.paymentIntent?.status === 'succeeded') {
        onSuccess();
      } else {
        setError('Oops, something went wrong, please try again!');
      }
    }
  }, [stripe, clientSecret, onSuccess]);

  useEffect(() => {
    handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{error ? <p>{error}</p> : null}</>;
};

const PaymentMethodUpdateModal = ({
  onSuccess,
  onClose,
}: {
  onClose: () => void;
  onSuccess: (data: { paymentMethodId: string }) => void;
}) => (
  <Dialog open={true} onOpenChange={onClose}>
    <DialogContent>
      <DialogClose onClick={onClose} />
      <div>
        <h3 className='text-2xl font-bold mb-1 font-secondary'>Update Payment Method</h3>
        <p className='mb-6 text-neutral-100'>You need to update your payment details to procceed</p>
        <StripeCreatePaymentMethod onSuccess={onSuccess} />
      </div>
    </DialogContent>
  </Dialog>
);
