import Image from 'next/image';

import GirlsWithTabletDesktopImage from '~/assets/images/upsells/book/girls-with-tablet-desktop.png';
import GirlsWithTabletMobileImage from '~/assets/images/upsells/book/girls-with-tablet-mobile.png';
import ScribbledCalendarImage from '~/assets/images/upsells/book/scribbled-calendar.png';
import WeekCoverOne from '~/assets/images/upsells/book/week-cover-1.png';
import WeekCoverTwo from '~/assets/images/upsells/book/week-cover-2.png';
import WeekCoverThree from '~/assets/images/upsells/book/week-cover-3.png';
import WeekCoverFour from '~/assets/images/upsells/book/week-cover-4.png';
import { Content } from '~/components/content';

import WeekCard from './week-card';

const Unlock = () => {
  return (
    <Content gridClassName='col-[edge-left_/_edge-right]'>
      <div className='flex md:flex-row flex-col'>
        <div className='flex-1'>
          <div className='flex flex-col gap-10 md:gap-12 md:px-8 py-6 md:pt-24 md:pb-16'>
            <div className='flex flex-col items-center gap-8 md:gap-12'>
              <h2 className='font-tertiary font-bold text-3xl md:text-5xl text-center leading-none'>
                Unlock your body’s natural healing potential
              </h2>
              <Image
                src={GirlsWithTabletMobileImage}
                width={327}
                height={277}
                alt='Unlock your body’s natural healing potential'
                className='md:hidden w-full'
              />
              <div className='flex md:flex-row flex-col items-center gap-6'>
                <Image src={ScribbledCalendarImage} width={134} height={94} alt='Calendar scribble' />
                <p className='md:max-w-[24.1875rem] font-tertiary text-2xl md:text-left text-center leading-none'>
                  <span className='font-bold'>The Cortisol Reset Blueprint</span> follows a gentle, structured —just
                  like your body’s natural cycles—guiding you back to balance step by step.
                </p>
              </div>
            </div>
            <div className='flex flex-col gap-4'>
              <WeekCard
                week={1}
                title='Understanding & Resetting Cortisol'
                items={[
                  'Reconnect with your body’s stress rhythms and reset cortisol naturally',
                  'Identify hidden stressors that have been keeping you stuck in survival mode',
                  'Create a morning & evening routine that supports deep restoration',
                ]}
                image={WeekCoverOne}
              />
              <WeekCard
                week={2}
                title='Detoxing Stress & Supporting the Adrenals'
                items={[
                  'Clear out the toxins, foods, and habits that silently disrupt your hormones',
                  'Support gut and liver health for better detoxification and metabolism',
                  'Learn how to nourish and replenish your adrenals for long-term energy',
                ]}
                image={WeekCoverTwo}
              />
              <WeekCard
                week={3}
                title='Nervous System Regulation & Emotional Resilience'
                items={[
                  'Train your body to respond to stress with ease, not exhaustion',
                  'Practice breathwork, movement, and nervous system resets for instant calm',
                  'Align your daily rhythms with your natural hormonal cycles',
                ]}
                image={WeekCoverThree}
              />
              <WeekCard
                week={4}
                title='Long-Term Cortisol Mastery & Harmonia Living'
                items={[
                  'Discover the key lab tests to track your hormone health',
                  'Learn how metabolism, thyroid, and stress resilience are all connected',
                  'Build a sustainable lifestyle rooted in Harmonia’s principles',
                ]}
                image={WeekCoverFour}
              />
            </div>
          </div>
        </div>

        <div className='hidden md:block flex-1 md:max-w-[12rem] lg:max-w-[20rem] xl:max-w-[29.0625rem]'>
          <Image
            src={GirlsWithTabletDesktopImage}
            width={465}
            height={663}
            alt='Unlock your body’s natural healing potential'
            className='top-0 sticky py-16'
          />
        </div>
      </div>
    </Content>
  );
};

export default Unlock;
