import { CreateUpsellNextStepsResponse, Product } from '@menesko/models-harmonia';
import { Button } from '@shared/design-system/atoms/button';
import { cn } from '@shared/design-system/utils/shadcn';
import { PaymentElement } from '@shared/payments';
import { ChevronRight } from 'lucide-react';
import { useState } from 'react';

import { useUpsellMutation } from '~/hooks/use-upsell-mutation';
import { useAppLead } from '~/hooks/useAppLead';
import { useCheckout } from '~/hooks/useCheckout';
import { NextStepsHandler } from '~/layouts/upsells/next-steps-handler';


export const UpsellButton = ({
  product,
  buttonCopy = 'Pay Now',
  onSuccess,
}: {
  product: Product;
  buttonCopy?: string;
  onSuccess: () => void;
}) => {
  const [nextSteps, setNextSteps] = useState<CreateUpsellNextStepsResponse>();
  const { paymentProvider, id: leadId } = useAppLead();
  const { currency, trackPurchase } = useCheckout();

  const { mutateAsync: upsellMutation, isLoading: upsellIsLoading } = useUpsellMutation({
    onSuccess: (data) => {
      if (data.success && data.paymentProvider === 'stripe') {
        handleSuccess();
      } else {
        setNextSteps(data as CreateUpsellNextStepsResponse);
      }
    },
    onError: (error) => {
      if (error.code === 'order_not_found') {
        onSuccess();
      } else {
        setNextSteps({
          code: 'rejected',
          success: false,
        });
      }
    },
  });

  const isLoading = upsellIsLoading;

  const handleNativeClick = () => {
    setNextSteps(undefined);

    return upsellMutation({
      leadId: leadId as string,
      productId: product.id,
      currency,
      paymentProvider: paymentProvider || 'stripe',
    });
  };

  const handlePaypalOrderCreation = async (): Promise<{ id: string }> => {
    return (await handleNativeClick()) as unknown as Promise<{ id: string }>;
  };

  const handleSuccess = () => {
    setNextSteps(undefined);

    trackPurchase(product.id);

    onSuccess();
  };

  const handleModalClose = () => {
    setNextSteps(undefined);
  };

  return (
    <>
      {paymentProvider === 'stripe' ? (
        <Button
          className={cn('w-full', isLoading ? 'justify-center' : 'justify-between')}
          variant='secondary'
          size='md'
          onClick={handleNativeClick}
          isLoading={isLoading}
        >
          <div style={{ width: '11px' }} /> {buttonCopy} <ChevronRight className='!w-6 !h-6' />
        </Button>
      ) : (
        <div className='mb-2 w-full'>
          <PaymentElement.Paypal
            createOrder={handlePaypalOrderCreation}
            options={{ isSubscription: false, currency }}
            onSuccess={handleSuccess}
          />
        </div>
      )}
      {nextSteps ? (
        <NextStepsHandler
          nextSteps={nextSteps}
          onClose={handleModalClose}
          onRetry={handleNativeClick}
          onSuccess={handleSuccess}
        />
      ) : null}
    </>
  );
};
