import { generalUtils } from '@shared/common';
import React from 'react';

import { UpsellButton } from '~/components/upsell-button';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useCheckout } from '~/hooks/useCheckout';
import { useProducts } from '~/hooks/useProducts';

const UPGRADE_PRODUCT_ID = '36';

const ProductCard = ({ nextRoute }: { nextRoute: string }) => {
  const { currency } = useCheckout();
  const { findProductById, findProductPrice } = useProducts();
  const product = findProductById(UPGRADE_PRODUCT_ID);
  const { pushNextRoute } = useAppRouter(nextRoute);

  const productPrice = findProductPrice(product, currency);
  const finalPrice = productPrice?.finalPrice || 0;
  const price = productPrice?.price || 0;

  const handleSuccess = () => {
    pushNextRoute();
  };

  return (
    <div className='bg-white shadow-[0px_0px_0px_1px_rgba(0,0,0,0.05),0px_1px_3px_0px_rgba(0,0,0,0.10),0px_2px_12px_0px_rgba(0,0,0,0.05)] p-4 rounded-xl'>
      {product ? (
        <div className='flex flex-col gap-3'>
          <div className='flex flex-col gap-1'>
            <p className='font-medium text-base'>One-time payment</p>
            <div className='flex flex-col'>
              <p className='font-tertiary font-bold text-3xl'>{generalUtils.formatPrice(finalPrice, currency)}</p>
              <p className='text-xs'>
                Regular price{' '}
                <span className='font-bold text-red-600'>{generalUtils.formatPrice(price, currency)}</span>
              </p>
            </div>
          </div>
          <div className='flex flex-col items-center gap-3'>
            <UpsellButton product={product} buttonCopy='Join now' onSuccess={handleSuccess} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductCard;
